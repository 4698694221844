import SvgIconBackLarge from '../@svg/SvgBackLarge';
import { TButtonCustom } from './interface/interface';

export default function ButtonArrowBack({ subClass = '', onClick }: TButtonCustom) {
  return (
    <button className={`btn-arrow-back ${subClass}`} onClick={onClick}>
      <SvgIconBackLarge />
    </button>
  );
}
