import { Checkbox, CheckboxProps } from 'antd';
import './index.scss';

interface CustomCheckboxProps extends CheckboxProps {
  clasName?: string;
}

const CustomCheckbox = (props: CustomCheckboxProps) => {
  const { clasName, ...restProps } = props;

  return (
    <Checkbox className={`ant-custom-checkbox ${clasName ?? ''}`} {...restProps}>
      {' '}
    </Checkbox>
  );
};

export default CustomCheckbox;
