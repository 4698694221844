export enum ChannelRegister {
  CONNECT = 'CONNECT',
  DISCONNECT = 'DISCONNECT',
  JOIN_CONVERSATION = 'JOIN_CONVERSATION',

  //Notify
  SYSTEM = 'SYSTEM',
  BIZ_NOTIFICATION = 'BIZ_NOTIFICATION',
  BIZ_NOTIFICATION_READ = 'BIZ_NOTIFICATION_READ',

  //message
  CREATE_CONVERSATION = 'CREATE_CONVERSATION',
  SEND_MESSAGE = 'SEND_MESSAGE',
  SEND_INQUIRY_MESSAGE = 'SEND_INQUIRY_MESSAGE',
  READ_MESSAGE = 'READ_MESSAGE',
}
