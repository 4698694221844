import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { TInitState } from '../util/interface';

export type TProfile = {
  id: string;
  name: string;
  avatar: string;
  password: string;
};

type TInitProfile = TInitState & { data: TProfile };

const initialState: TInitProfile = {
  loading: false,
  data: {
    id: '',
    name: '',
    avatar: '',
    password: '',
  },
};

export const DetailProfile = createAsyncThunk(
  'DetailProfile',
  async (userId: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      // const response = await api.get('user-token-ebay/count-syncing-ebay')
      // return fulfillWithValue(response)
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  },
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DetailProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(DetailProfile.fulfilled, (state, action) => {
        // console.log('fulfilled', action);
        state.loading = false;
      })
      .addCase(DetailProfile.rejected, (state, action) => {
        // console.log('rejected', action);
        state.loading = false;
      });
  },
});

export const selectAuth = (state: RootState) => state.profile;
export default profileSlice.reducer;
