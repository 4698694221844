import './index.scss';
import { TButtonCustom } from './interface/interface';

const ButtonCancel = ({ value, ...props }: TButtonCustom) => {
  return (
    <button className="button-cancel" disabled={props.disabled} onClick={props.onClick}>
      {value}
    </button>
  );
};
export default ButtonCancel;
