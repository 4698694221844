export const SvgINoti = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.91667 11.0927L6.87933 7H5.45833V5.83333H8.2595L8.2945 9.926H9.6V11.0927H6.91667ZM6.625 3.857C6.61665 3.61002 6.70305 3.36919 6.8665 3.18383C7.02672 3.00494 7.25617 2.91589 7.55483 2.91667C7.8535 2.91667 8.0845 3.00611 8.24783 3.185C8.41117 3.36311 8.49244 3.58711 8.49167 3.857C8.49167 4.123 8.41 4.34389 8.24667 4.51967C8.08333 4.69544 7.85272 4.78333 7.55483 4.78333C7.25694 4.78333 7.0275 4.69544 6.8665 4.51967C6.70243 4.33887 6.61573 4.10097 6.625 3.857ZM7.5 12.8333C9.0471 12.8333 10.5308 12.2188 11.6248 11.1248C12.7188 10.0308 13.3333 8.5471 13.3333 7C13.3333 5.4529 12.7188 3.96917 11.6248 2.87521C10.5308 1.78125 9.0471 1.16667 7.5 1.16667C5.9529 1.16667 4.46917 1.78125 3.37521 2.87521C2.28125 3.96917 1.66667 5.4529 1.66667 7C1.66667 8.5471 2.28125 10.0308 3.37521 11.1248C4.46917 12.2188 5.9529 12.8333 7.5 12.8333ZM7.5 14C6.58075 14 5.6705 13.8189 4.82122 13.4672C3.97194 13.1154 3.20026 12.5998 2.55025 11.9497C1.90024 11.2997 1.38463 10.5281 1.03284 9.67878C0.68106 8.8295 0.5 7.91925 0.5 7C0.5 6.08075 0.68106 5.1705 1.03284 4.32122C1.38463 3.47194 1.90024 2.70026 2.55025 2.05025C3.20026 1.40024 3.97194 0.884626 4.82122 0.532843C5.6705 0.18106 6.58075 -1.36979e-08 7.5 0C9.35651 2.76642e-08 11.137 0.737498 12.4497 2.05025C13.7625 3.36301 14.5 5.14348 14.5 7C14.5 8.85651 13.7625 10.637 12.4497 11.9497C11.137 13.2625 9.35651 14 7.5 14Z"
      fill="#9D9D9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.91667 11.0927L6.87933 7H5.45833V5.83333H8.2595L8.2945 9.926H9.6V11.0927H6.91667ZM6.625 3.857C6.61665 3.61002 6.70305 3.36919 6.8665 3.18383C7.02672 3.00494 7.25617 2.91589 7.55483 2.91667C7.8535 2.91667 8.0845 3.00611 8.24783 3.185C8.41117 3.36311 8.49244 3.58711 8.49167 3.857C8.49167 4.123 8.41 4.34389 8.24667 4.51967C8.08333 4.69544 7.85272 4.78333 7.55483 4.78333C7.25694 4.78333 7.0275 4.69544 6.8665 4.51967C6.70243 4.33887 6.61573 4.10097 6.625 3.857ZM7.5 12.8333C9.0471 12.8333 10.5308 12.2188 11.6248 11.1248C12.7188 10.0308 13.3333 8.5471 13.3333 7C13.3333 5.4529 12.7188 3.96917 11.6248 2.87521C10.5308 1.78125 9.0471 1.16667 7.5 1.16667C5.9529 1.16667 4.46917 1.78125 3.37521 2.87521C2.28125 3.96917 1.66667 5.4529 1.66667 7C1.66667 8.5471 2.28125 10.0308 3.37521 11.1248C4.46917 12.2188 5.9529 12.8333 7.5 12.8333ZM7.5 14C6.58075 14 5.6705 13.8189 4.82122 13.4672C3.97194 13.1154 3.20026 12.5998 2.55025 11.9497C1.90024 11.2997 1.38463 10.5281 1.03284 9.67878C0.68106 8.8295 0.5 7.91925 0.5 7C0.5 6.08075 0.68106 5.1705 1.03284 4.32122C1.38463 3.47194 1.90024 2.70026 2.55025 2.05025C3.20026 1.40024 3.97194 0.884626 4.82122 0.532843C5.6705 0.18106 6.58075 -1.36979e-08 7.5 0C9.35651 2.76642e-08 11.137 0.737498 12.4497 2.05025C13.7625 3.36301 14.5 5.14348 14.5 7C14.5 8.85651 13.7625 10.637 12.4497 11.9497C11.137 13.2625 9.35651 14 7.5 14Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);
