import { StyledModal } from '../StyledModal';
import './modal-information.scss';

type TPropsModalInformation = {
  onCancel: () => void;
  open: boolean;
  title: string;
  content: string | React.ReactElement;
};

export const ModalInformation = ({ onCancel, open, title, content }: TPropsModalInformation) => (
  <StyledModal
    children={content}
    isOpen={open}
    onCancel={onCancel}
    modalProps={{
      title: title,
      closable: true,
      closeIcon: true,
      maskClosable: true,
      footer: null,
      className: 'modal-information',
    }}
  />
);
